






















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SlideTemplate, TemplateType } from '../../../types/types';

@Component
export default class ConfigureTemplateModal extends Vue {
  @Prop({ type: Boolean }) show!: boolean;

  TemplateType = TemplateType;

  hideModal(): void {
    this.$emit('hide');
  }

  setTemplate(template: SlideTemplate): void {
    this.$emit('setSlideTemplate', template);
  }
}
