














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GeoComponent from '../../GeoComponent.vue';
import * as htmlToImage from 'html-to-image';

@Component({
  components: {
    GeoComponent,
  },
})
export default class GeoModal extends Vue {
  @Prop({
    type: Boolean,
  })
  show!: boolean;

  $refs!: {
    geoComponent: HTMLElement;
  };

  async save(): Promise<void> {
    this.hideOverlays(true);
    let mapImage = await htmlToImage.toJpeg(document.querySelector('raptr-geo #map') as HTMLElement);
    this.hideOverlays(false);
    this.$emit('save', mapImage);
    this.hideModal();
  }

  @Watch('show')
  onShow(): void {
    // trigger resize to repaint the map when lost
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  hideModal(): void {
    this.$emit('hide');
  }

  // stolen from rapt-geo/js/map/utils.js
  elementVisbility: any = {};
  hideOverlays(hide: boolean): void {
    const elements = document.querySelectorAll('raptr-geo .geo-overlay') as any;
    elements.forEach((element: { id: any; style: { display: any } }) => {
      const id = element.id;
      const display = element.style.display;
      // store their previous 'state'
      hide && (this.elementVisbility[id] = display === '' ? '' : display);
      // for each, if it's not hidden hide it or restore its old display style
      element.style.display = hide ? 'none' : this.elementVisbility[id];
    });
  }
}
