/* tslint:disable */
/* eslint-disable */
/**
 * RAPTR API
 * The RAPTR API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Country } from '../model';
/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCountryByName: async (countryName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryName' is not null or undefined
            assertParamExists('findCountryByName', 'countryName', countryName)
            const localVarPath = `/country/{countryName}`
                .replace(`{${"countryName"}}`, encodeURIComponent(String(countryName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCountryByName(countryName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Country>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findCountryByName(countryName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCountries(options?: any): AxiosPromise<Array<Country>> {
            return localVarFp.findAllCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCountryByName(countryName: string, options?: any): AxiosPromise<Country> {
            return localVarFp.findCountryByName(countryName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findCountryByName operation in CountryApi.
 * @export
 * @interface CountryApiFindCountryByNameRequest
 */
export interface CountryApiFindCountryByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof CountryApiFindCountryByName
     */
    readonly countryName: string
}

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public findAllCountries(options?: any) {
        return CountryApiFp(this.configuration).findAllCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CountryApiFindCountryByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public findCountryByName(requestParameters: CountryApiFindCountryByNameRequest, options?: any) {
        return CountryApiFp(this.configuration).findCountryByName(requestParameters.countryName, options).then((request) => request(this.axios, this.basePath));
    }
}
