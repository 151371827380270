







































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Classification, BannerLocation } from '../../../types/types';

@Component
export default class ClassificationModal extends Vue {
  $refs!: {
    labelForm: HTMLFormElement;
  };

  @Prop({
    type: Boolean,
  })
  show!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  classification!: Classification;

  private colors: Array<string> = ['#ffffff', '#000000', '#ff0000', '#00b300', '#4169E1'];

  // strictly to avoid v-for, v-if filtering, and filter functions
  private comboColors: Array<string> = ['#ffffff', '#ff0000', '#00b300', '#4169E1'];

  getBG(color: string, combo?: boolean): string {
    return combo ? `background: linear-gradient(90deg, #000000 50%, ${color} 50%)` : `background-color: ${color}`;
  }

  setTheme(color: string, target: string): void {
    let themeChange = {};
    switch (target) {
      case 'background-color':
        themeChange = { 'background-color': color };
        break;
      case 'color':
        themeChange = { color: color };
        break;
      default:
        themeChange = { 'background-color': '#000000', color: color };
        break;
    }
    this.$store.dispatch('setClassificationTheme', themeChange);
  }

  BannerLocation = BannerLocation;

  hideModal(): void {
    this.$emit('hide');
  }
}
