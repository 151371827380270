var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-color":"white","width":"50%","max-width":"900px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-5",attrs:{"color":"#263238","dark":""}},[_c('v-toolbar-title',[_vm._v("Configure slide template")]),_c('v-spacer'),_c('v-btn',{staticClass:"black--text",attrs:{"color":"accent","light":""},on:{"click":function($event){$event.stopPropagation();return _vm.hideModal.apply(null, arguments)}}},[_vm._v(" Cancel ")])],1),_c('v-card-text',[_c('p',{staticClass:"mb-5"},[_vm._v(" Select a slide template from the options below. The grey zones represent placeholder areas into which you can then load predefined datasets. ")]),_c('div',{staticClass:"text-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"ma-5 d-inline-block",attrs:{"src":"/img/wireframe-one-full.jpg","alt":"four top full","height":"147","width":"199"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',{on:{"click":function($event){return _vm.setTemplate({ name: _vm.TemplateType.ONE_FULL, moduleCount: 1 })}}},[_vm._v("Full-width")])],1):_vm._e()],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"ma-5 d-inline-block",attrs:{"src":"/img/wireframe-two-columns.jpg","alt":"two columns","height":"147","width":"199"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',{on:{"click":function($event){return _vm.setTemplate({ name: _vm.TemplateType.TWO_COLUMNS, moduleCount: 2 })}}},[_vm._v("Two columns")])],1):_vm._e()],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"ma-5 d-inline-block",attrs:{"src":"/img/wireframe-four-grid.jpg","alt":"four grid","height":"147","width":"199"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',{on:{"click":function($event){return _vm.setTemplate({ name: _vm.TemplateType.FOUR_GRID, moduleCount: 4 })}}},[_vm._v("Four grid")])],1):_vm._e()],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"ma-5 d-inline-block",attrs:{"src":"/img/wireframe-three-left-full.jpg","alt":"three left full","height":"147","width":"199","gradient":"to top right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',[_vm._v("Coming soon")])],1):_vm._e()],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"ma-5 d-inline-block",attrs:{"src":"/img/wireframe-three-right-full.jpg","alt":"three right full","height":"147","width":"199","gradient":"to top right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',[_vm._v("Coming soon")])],1):_vm._e()],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"ma-5 d-inline-block",attrs:{"src":"/img/wireframe-four-top-full.jpg","alt":"one full","height":"147","width":"199","gradient":"to top right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',[_vm._v("Coming soon")])],1):_vm._e()],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }