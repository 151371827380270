

























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Country, CountryApi } from '@raptr/api';
import { SlideModule, ModuleContentType, ModuleType } from '../../types/types';

interface CountryRegion {
  region: string;
  countries: Country[];
  active: boolean;
}

@Component({})
export default class CountryDataModule extends Vue {
  moduleData: SlideModule = {
    moduleType: ModuleType.COUNTRY_DATA,
    contentType: ModuleContentType.TEXT_BULLETS,
  };
  countries: Country[] = [];
  selectedText = [];
  searchCountry = '';
  ModuleType = ModuleContentType;
  textContentFormat: ModuleContentType = ModuleContentType.TEXT_BULLETS;
  textContentFormatTypes = [
    { label: 'Bulleted list', ModuleType: ModuleContentType.TEXT_BULLETS },
    { label: 'Paragraph text', ModuleType: ModuleContentType.TEXT_FREEFORM },
  ];

  async created(): Promise<void> {
    this.$emit('toggleLoading', true);
    const countryApi = new CountryApi(undefined, 'https://raptr-api.noskill.io', undefined);
    const response = await countryApi.findAllCountries();
    this.countries = response.data;
    this.$emit('toggleLoading', false);
  }

  @Watch('selectedText', { deep: true })
  onSelectionChange(): void {
    this.moduleData = { ...this.moduleData, textContent: this.selectedText };
    this.$emit('unsavedChanges', this.selectedText.length > 0);
    this.$emit('input', this.moduleData); // use input as the event so we can easily switch to vmodel in the future
  }

  collapseLists(): void {
    const countryTree: any = this.$refs['country-groups'];
    for (let x = 0; x < countryTree?.length; x++) {
      countryTree[x].isActive = false;
    }
    const regionTree: any = this.$refs['region-groups'];
    for (let x = 0; x < regionTree?.length; x++) {
      regionTree[x].isActive = false;
    }
  }

  reset(): void {
    let el = document.getElementById('CountryDataModuleRow');
    this.selectedText = [];
    this.collapseLists();
    if (el) {
      el.scrollTop = 0;
    }
  }

  // it might make sense to retrieve the data in this way from the server
  get countryRegions(): CountryRegion[] {
    const countryRegions: CountryRegion[] = [];

    // group countries by region
    this.countries.forEach((country) => {
      let countryRegion = countryRegions.find((region) => region.region === country.region);
      if (!countryRegion) {
        countryRegions.push({ region: country.region, countries: [country], active: false });
      } else {
        countryRegion.countries.push(country);
      }
    });

    countryRegions.sort((a, b) => {
      return a.region.localeCompare(b.region);
    });

    // sort alphabetically
    return countryRegions;
  }

  // COUNTRY SEARCH
  setSearchCountry(country: string): void {
    if (country.length > 2) {
      this.searchCountry = country;
    } else if (country.length == 0) {
      this.searchCountry = '';
    }
  }

  @Watch('searchCountry', { deep: true })
  autoExpandRegions(): void {
    if (this.searchCountry.length < 3) {
      this.collapseLists();
    } else if (this.filterCountryRegions?.length == 1) {
      const tree: any = this.$refs['region-groups'];
      for (let x = 0; x < tree.length; x++) {
        tree[x].isActive = true;
      }
    }
  }

  get filterCountryRegions(): CountryRegion[] {
    if (!this.searchCountry || this.searchCountry.length < 3) {
      return this.countryRegions;
    }
    return this.countryRegions.filter((region) => {
      return (
        region.countries.filter((country) => {
          if (country.countryName.toLowerCase().match(this.searchCountry.toLowerCase())) {
            return true;
          }
        }).length > 0
      );
    });
  }

  filterCountries(region: CountryRegion): Country[] {
    return region.countries.filter((country) => {
      if (country.countryName.toLowerCase().match(this.searchCountry.toLowerCase())) {
        return true;
      }
    });
  }
}
