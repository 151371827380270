






























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { SlideModule, ModuleContentType, ModuleType } from '../../../types/types';
import CountryDataModule from '../../modules/CountryDataModule.vue';
import EquipmentSpecModule from '../../modules/EquipmentSpecModule.vue';

@Component({
  components: { EquipmentSpecModule, CountryDataModule },
})
export default class ConfigureModuleModal extends Vue {
  ModuleType = ModuleType;

  @Prop({ type: Boolean }) show!: boolean;

  moduleData: SlideModule = {
    moduleType: ModuleType.NOT_CONFIGURED,
    contentType: ModuleContentType.NOT_CONFIGURED,
  };
  unsavedChanges = false;
  loading = false;

  step = 1;
  moduleType: ModuleType = ModuleType.NOT_CONFIGURED;

  selectModule(moduleType: ModuleType): void {
    this.moduleType = moduleType;
    this.step = 2;
  }

  moduleTypeButtons: { icon: string; label: string; value: ModuleType; disabled?: boolean }[] = [
    {
      icon: 'mdi-map',
      label: 'Country Data',
      value: ModuleType.COUNTRY_DATA,
    },
    {
      icon: 'mdi-pistol',
      label: 'Equipment Data',
      value: ModuleType.EQUIPMENT_SPEC,
    },
    {
      icon: 'mdi-earth',
      label: 'Geo Map',
      value: ModuleType.MAP,
      disabled: true,
    },
    {
      icon: 'mdi-image-plus',
      label: 'Custom Image',
      value: ModuleType.CUSTOM_IMAGE,
      disabled: true,
    },
  ];

  hideModal(): void {
    this.reset();
    this.$emit('hide');
  }

  reset(): void {
    this.step = 1;
  }

  saveChanges(): void {
    this.$emit('save', this.moduleData);
    this.hideModal();
  }

  setModuleType(moduleType: ModuleType): void {
    this.moduleType = moduleType;
    this.step++;
  }
}
