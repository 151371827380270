
































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  SlideData,
  SlideTemplate,
  TemplateType,
  ModuleContentType,
  ModuleTemplate,
  SlideTheme,
  Classification,
  BannerLocation,
  ModuleType,
} from '../types/types';

import ConfigureTemplateModal from './modals/nested/ConfigureTemplateModal.vue';
import ModuleComponent from '../components/ModuleComponent.vue';
import GeoComponent from '../components/GeoComponent.vue';

@Component({
  components: {
    ConfigureTemplateModal,
    ModuleComponent,
    GeoComponent,
  },
})
export default class SlideComponent extends Vue {
  @Prop() slideId!: number;
  @Prop({ default: 'one-full' }) template!: string;
  @Prop({
    type: Object,
    required: true,
  })
  slideData!: SlideData;
  @Prop({
    type: Object,
    required: true,
  })
  slideTheme!: SlideTheme;
  @Prop({
    type: Object,
    required: true,
  })
  classification!: Classification;

  BannerLoc = BannerLocation;
  ModuleContentType = ModuleContentType;
  TemplateName = TemplateType;
  ModuleTemplate = ModuleTemplate;

  moduleKey = 0;

  modals = {
    configureTemplateModal: {
      show: false,
    },
    configureModuleModal: {
      show: false,
      targetModule: 0,
    },
  };

  configureSlideTemplate(): void {
    this.modals.configureTemplateModal.show = true;
  }

  setSlideTemplate(template: SlideTemplate): void {
    this.modals.configureTemplateModal.show = false;
    this.slideData.template = template;
    this.slideData.modules = new Array(template.moduleCount);
    for (let i = 0; i < template.moduleCount; i++) {
      this.slideData.modules[i] = {
        moduleType: ModuleType.NOT_CONFIGURED,
        contentType: ModuleContentType.NOT_CONFIGURED,
      };
    }
  }

  configureModule(n: number): void {
    this.modals.configureModuleModal.targetModule = n;
    this.modals.configureModuleModal.show = true;
  }

  // eslint-disable-next-line
  updateModuleData(moduleId: number, e: any): void {
    this.$emit('update:moduleData', { moduleId: moduleId, moduleData: e });
  }

  updateSlideTitle(e: FocusEvent): void {
    const titleElement = e.target as HTMLElement | null;

    if (titleElement) {
      let newTitle = titleElement.innerText.replace(/\r?\n|\r/g, ' ');
      titleElement.innerText = newTitle;
      this.slideData.title = newTitle;
    } else {
      console.log('Could not update slide title: no target element for FocusEvent.');
    }
  }
}
