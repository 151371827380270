









































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SlideModule, ModuleContentType, ModuleTemplate } from '../types/types';

import ConfigureModuleModal from './modals/nested/ConfigureModuleModal.vue';

@Component({
  components: {
    ConfigureModuleModal,
  },
})
export default class ModuleComponent extends Vue {
  @Prop() moduleData!: SlideModule;
  @Prop() moduleTemplate!: ModuleTemplate;

  ModuleContentType = ModuleContentType;
  ModuleTemplate = ModuleTemplate;

  overlayPermitted = true;
  contentEditableFlag = false;

  modals = {
    configureModuleModal: {
      show: false,
      moduleData: {
        type: ModuleContentType.NOT_CONFIGURED,
      },
    },
  };

  configureModule(): void {
    this.modals.configureModuleModal.show = true;
  }

  enableModuleEditing(): void {
    this.overlayPermitted = false;
    this.contentEditableFlag = true;
  }

  updateModuleContent(e: FocusEvent): void {
    this.overlayPermitted = true;
    this.contentEditableFlag = false;

    let targetElement = e.target as HTMLElement | null;

    if (targetElement) {
      // ids are set to the index of the element in the module, so we can safely cast to number
      let targetElementId = targetElement.id ? parseInt(targetElement.id) : 0;

      if (isNaN(targetElementId)) {
        console.log('Could not update module content: target element does not have a numerical ID.');
      } else {
        let newContent = targetElement?.innerText.replace(/\r?\n|\r/g, ' ');
        targetElement.innerText = newContent;
        this.moduleData.textContent ? (this.moduleData.textContent[targetElementId] = newContent) : '';
        this.moduleData.caption ? (this.moduleData.caption = newContent) : '';
      }
    } else {
      console.log('Could not update module content: no target element for FocusEvent.');
    }
  }

  @Watch('moduleData', { deep: true })
  onModuleDataChanged(): void {
    console.log('module data changed');
  }

  get imageMaxWidth(): number {
    switch (this.moduleTemplate) {
      case ModuleTemplate.ONE_FULL:
        return 1100;
      case ModuleTemplate.TWO_COLUMNS:
        return 745;
      case ModuleTemplate.FOUR_GRID:
        return 550;
    }
    return 0;
  }

  get imageMaxHeight(): number {
    switch (this.moduleTemplate) {
      case ModuleTemplate.ONE_FULL:
        return 650;
      case ModuleTemplate.TWO_COLUMNS:
        return 500;
      case ModuleTemplate.FOUR_GRID:
        return 300;
    }
    return 0;
  }
}
