





























































import { Component, Vue } from 'vue-property-decorator';
import { Equipment, EquipmentApi, Specification } from '@raptr/api';
import { SlideModule, ModuleContentType, ModuleType } from '../../types/types';

interface EquipmentGroup {
  type: string;
  equip: Equipment[];
  active: boolean;
}

@Component({
  components: {},
  data() {
    return {
      selectedEquip: undefined,
    };
  },
})
export default class Home extends Vue {
  moduleData: SlideModule = {
    moduleType: ModuleType.EQUIPMENT_SPEC,
    contentType: ModuleContentType.TEXT_BULLETS,
    header: 'Specifications',
  };

  equip: Equipment[] = [];
  selectedEquip!: Equipment;
  searchEquip = '';

  selectedSpecs: string[] = [];

  updateSpecs(spec: Specification, selected: boolean): void {
    const specText = `${spec.spec} ${spec.value}`;
    if (selected) {
      this.selectedSpecs.push(specText);
    } else {
      this.selectedSpecs = this.selectedSpecs.filter((s) => specText !== s);
    }
    this.moduleData = {
      ...this.moduleData,
      textContent: this.selectedSpecs,
    };
    this.$emit('unsavedChanges', this.selectedSpecs.length > 0);
    this.$emit('input', this.moduleData);
  }

  get selectedEquipSpecs(): Specification[] {
    return this.selectedEquip?.specifications ?? [];
  }

  async created(): Promise<void> {
    this.$emit('toggleLoading', true);
    const equipApi = new EquipmentApi(undefined, 'https://raptr-api.noskill.io', undefined);
    const response = await equipApi.findAllEquipment();
    this.equip = response.data;
    this.$emit('toggleLoading', false);
  }

  selectEquip(equip: Equipment): void {
    this.selectedEquip = equip;
    setTimeout(() => {
      const tree: any = this.$refs.tree;
      tree.updateAll(true);
    }, 0);
  }

  expandAll(expand: boolean): void {
    const tree: any = this.$refs.tree;
    tree.updateAll(expand);
  }

  // it might make sense to retrieve the data in this way from the server
  get equipGroups(): EquipmentGroup[] {
    const equipGroups: EquipmentGroup[] = [];

    // group equipment by type
    this.equip.forEach((equip) => {
      let equipGroup = equipGroups.find((group) => group.type === equip.type);
      if (!equipGroup) {
        equipGroups.push({ type: equip.type, equip: [equip], active: false });
      } else {
        equipGroup.equip.push(equip);
      }
    });

    equipGroups.sort((a, b) => {
      return a.type.localeCompare(b.type);
    });

    // sort alphabetically
    return equipGroups;
  }

  get filterEquip(): EquipmentGroup[] {
    if (!this.searchEquip) {
      return this.equipGroups;
    }
    return this.equipGroups.filter((group) => {
      return (
        group.equip.filter((equip) => {
          if (equip.name.toLowerCase().match(this.searchEquip.toLowerCase())) {
            return true;
          }
        }).length > 0
      );
    });
  }
}
