/* tslint:disable */
/* eslint-disable */
/**
 * RAPTR API
 * The RAPTR API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateEquipmentDto } from '../model';
// @ts-ignore
import { Equipment } from '../model';
/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEquipmentDto} createEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquipment: async (createEquipmentDto: CreateEquipmentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEquipmentDto' is not null or undefined
            assertParamExists('createEquipment', 'createEquipmentDto', createEquipmentDto)
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEquipmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEquipment: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/equipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEquipmentDto} createEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquipment(createEquipmentDto: CreateEquipmentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquipment(createEquipmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllEquipment(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Equipment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllEquipment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEquipmentDto} createEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquipment(createEquipmentDto: CreateEquipmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.createEquipment(createEquipmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEquipment(options?: any): AxiosPromise<Array<Equipment>> {
            return localVarFp.findAllEquipment(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createEquipment operation in EquipmentApi.
 * @export
 * @interface EquipmentApiCreateEquipmentRequest
 */
export interface EquipmentApiCreateEquipmentRequest {
    /**
     * 
     * @type {CreateEquipmentDto}
     * @memberof EquipmentApiCreateEquipment
     */
    readonly createEquipmentDto: CreateEquipmentDto
}

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * 
     * @param {EquipmentApiCreateEquipmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public createEquipment(requestParameters: EquipmentApiCreateEquipmentRequest, options?: any) {
        return EquipmentApiFp(this.configuration).createEquipment(requestParameters.createEquipmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public findAllEquipment(options?: any) {
        return EquipmentApiFp(this.configuration).findAllEquipment(options).then((request) => request(this.axios, this.basePath));
    }
}
