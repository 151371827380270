





























































































import { Component, Ref, Vue } from 'vue-property-decorator';
import {
  SlideModule,
  ModuleContentType,
  ModuleType,
  SlideData,
  SlideTheme,
  Classification,
  Modal,
  TemplateType,
} from '../types/types';

import SlideComponent from '../components/SlideComponent.vue';
import ClassificationModal from '../components/modals/global/ClassificationModal.vue';
import GeoModal from '../components/modals/global/GeoModal.vue';
import GeoComponent from '../components/GeoComponent.vue';

@Component({
  components: {
    GeoComponent,
    SlideComponent,
    ClassificationModal,
    GeoModal,
  },
})
export default class Editor extends Vue {
  @Ref() slideComponents!: SlideComponent[];

  mounted(): void {
    this.checkResolution();
  }

  get slideData(): Array<SlideData> {
    return this.$store.getters.getSlideData;
  }
  get slideTheme(): SlideTheme {
    return this.$store.getters.getSlideTheme;
  }
  get classification(): Classification {
    return this.$store.getters.getClassification;
  }
  get unsavedChanges(): boolean {
    return this.$store.getters.getUnsavedChanges;
  }
  // The get modals() return format is an object of Modal objects
  get modals(): { [key: string]: Modal } {
    return this.$store.getters.getModalData;
  }

  slideKey = 0;
  resolutionAlert = false;
  slideHoverIndex = -1;

  // targetVisibility: optional parameter you can provide to set modal visibility explicitely
  toggleModalVisibility(targetModal: string, targetVisibility?: boolean): void {
    this.$store.dispatch('toggleModalVisibility', { targetModal, targetVisibility });
  }

  checkResolution(): void {
    if (screen.height < 1080) {
      this.resolutionAlert = true;
    }
  }

  scrollTo(slideIndex: number): void {
    let top = (this.slideComponents[slideIndex].$el as HTMLElement).offsetTop - 60;
    window.scrollTo({
      top: top,
      behavior: 'smooth',
    });
  }

  saveChanges(): void {
    this.$store.dispatch('saveChanges');
  }

  addNewSlide(): void {
    let newSlide: SlideData = {
      title: 'New slide',
      template: {
        templateType: TemplateType.NOT_CONFIGURED,
        moduleCount: 0,
      },
      modules: [],
    };
    this.$store.dispatch('addNewslide', newSlide);
    setTimeout(() => {
      this.scrollTo(this.slideData.length - 1);
    }, 100);
  }

  updateModuleData(slideId: number, e: { moduleId: number; moduleData: SlideModule }): void {
    this.slideData[slideId].modules[e.moduleId] = e.moduleData;
    this.slideKey = this.slideKey + 1;
  }

  // temporary for singleton geo modal instance
  activeModule = {
    slideId: 0,
    moduleId: 0,
  };

  showGeoModalForModule(slideId: number, moduleId: number): void {
    this.activeModule = { slideId: slideId, moduleId: moduleId };
    this.toggleModalVisibility('geoModal', true);
  }

  addMapToModule(base64Image: string): void {
    let moduleData = {
      moduleType: ModuleType.COUNTRY_DATA,
      contentType: ModuleContentType.IMAGE,
      imgUrl: base64Image,
    };
    this.$store.dispatch('updateModuleData', {
      targetSlideId: this.activeModule.slideId,
      targetModuleId: this.activeModule.moduleId,
      moduleData: moduleData,
    });
  }
}
